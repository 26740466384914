import styled, { css, DefaultTheme } from "styled-components";
import { Typography } from "styles/mixins";
import { stlyedBreakPoints, IResponsiveProp } from "styles/utils";

interface IProps {
  color?: keyof DefaultTheme["palette"];
  typography?: keyof typeof Typography;
  gutter?: IResponsiveProp<number>;
  noWrap?: boolean;
}

const Gutter = stlyedBreakPoints<IProps, number>("gutter");

// TODO: rm gutter and create a Stack component?!
const Text = styled.span<IProps>`
  ${({ typography = "t16Fakt" }) => Typography[typography]};
  color: ${({ theme, color }) => color && theme.palette[color]};
  white-space: ${(props) => props.noWrap && "nowrap"};

  ${Gutter((gutter) => {
    return css`
      margin-bottom: ${({ theme }) => !!gutter && theme.spacing(gutter)};
    `;
  })}
`;

export default Text;
