import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { Link } from "./ui";
import { useFormatMessage } from "context/Intl";
import { up } from "styled-breakpoints";

interface IProps {
  searchBoxOnly: boolean;
}

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
  justify-content: space-between;
  align-items: center;
  display: none;

  ${up("lg")} {
    display: flex;
  }
`;

const Links = styled.div`
  font-size: 14px;
  padding: ${({ theme }) => theme.spacing(2.5)} 0;

  > a {
    margin-right: ${({ theme }) => theme.spacing(3)};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Search = styled.div`
  flex-direction: column;
  justify-content: center;
  display: inline-flex;

  .gsc-input {
    background-image: none !important;
    color: #313335;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;

    &::placeholder {
      color: #a7afb9;
    }
  }
`;

const SearchBar: React.FC<IProps> = ({ searchBoxOnly }) => {
  const formatMessage = useFormatMessage();
  const gcse = searchBoxOnly ? "gcse-searchbox-only" : "gcse-searchbox";

  useLayoutEffect(() => {
    if (typeof window === "undefined") return;

    const script = document.createElement("script");
    script.setAttribute("src", "https://cse.google.com/cse.js?cx=012121439156760102823:kdnfhsxm2c0");
    document.head.appendChild(script);

    (window as any).__gcse = {
      initializationCallback: () => {
        document.getElementById("gsc-i-id1")?.setAttribute("placeholder", formatMessage("Search Montefiore"));
      },
    };
  }, []);

  return (
    <Container>
      <Links>
        <Link to={"https://www.montefioreeinstein.org/"}>Montefioreeinstein.org</Link>
        <Link to={"https://www.cham.org/"}>Cham.org</Link>
        <Link to={"https://www.montefiore.org/manage-your-health-online"}>MyChart</Link>
        <Link to={"https://www.montefiore.org/payment-portal"}>{formatMessage("Pay My Bill")}</Link>
      </Links>
      <Search>
        <div className={gcse} data-resultsurl="/search" />
      </Search>
    </Container>
  );
};

export default SearchBar;
