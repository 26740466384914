import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import useMediaQuery from "hooks/useMediaQuery";

interface IProps {
  title: string;
  body: string;
  image?: any;
  link?: string;
}

const Container = styled.div`
  width: 100vw;
  position: relative;
`;

const Image = styled.div`
  height: 40vh;

  ${up("lg")} {
    height: auto;
  }
`;

const Info = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  padding-left: 5vw;
  padding-right: 5vw;

  ${up("lg")} {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 170px;
    align-items: center;
  }
`;

const Title = styled.div`
  flex: 1;
  white-space: nowrap;
  margin-right: 5vw;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const Body = styled.div`
  flex: 3;

  ${up("lg")} {
    max-width: 50vw;
  }
`;

const FullImage: React.FC<IProps> = (props) => {
  const { title, image, body } = props;
  const isLg = useMediaQuery("lg");

  return (
    <Container>
      <UI.Wrap fluid>
        {image && (
          <Image>
            <UI.Image style={{ height: "100%" }} {...image} alt={image?.alt} />
          </Image>
        )}
      </UI.Wrap>
      <Info>
        <Title>
          <UI.Text typography={"h4Leitura"} color={isLg ? "white" : "plainBlack"}>
            {title}
          </UI.Text>
        </Title>
        <Body>
          <UI.Text typography={"t16Fakt"} color={isLg ? "white" : "plainBlack"}>
            {body}
          </UI.Text>
        </Body>
      </Info>
    </Container>
  );
};

export default FullImage;
