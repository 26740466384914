import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import useClickOutside from "hooks/useClickOutside";
import { styles } from "styles/map";
import MarkerIcon from "assets/map-marker.svg";
import { Typography } from "styles/mixins";

type IMarker = {
  position?: { lat?: number; lng?: number };
  title?: string;
  location?: {
    address_line1?: string;
    address_line2?: string;
    locality?: string;
    administrative_area?: string;
    postal_code?: string;
  };
};

interface IProps {
  zoom: number;
  center: { lat?: number; lng?: number };
  markers: IMarker[];
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const MarkerContainer = styled.div`
  position: relative;
`;

const MarkerPin = styled.a`
  cursor: pointer;
  display: block;

  & svg {
    height: 26px;
    width: 20px;
  }
`;

const MarkerBubble = styled.aside`
  ${Typography.t14Fakt}

  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.25);
  padding: ${({ theme }) => theme.spacing(1)};
  position: absolute;
  bottom: calc(100% + 8px);
  left: -67px;
  width: 150px;
  z-index: 9;
`;

const MarkerTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`;

const Marker: React.FC<any> = (marker) => {
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  const toggleBubble = () => setInfoIsVisible(!infoIsVisible);
  const [$marker, hasClickedOutsideOfMarker] = useClickOutside<HTMLDivElement>();

  useEffect(() => {
    if (hasClickedOutsideOfMarker && infoIsVisible) setInfoIsVisible(false);
  }, [hasClickedOutsideOfMarker, infoIsVisible]);

  return (
    <MarkerContainer ref={$marker}>
      <MarkerPin onClick={toggleBubble}>
        <MarkerIcon />
      </MarkerPin>
      {infoIsVisible && (
        <MarkerBubble>
          <MarkerTitle>{marker.title}</MarkerTitle>
          {marker.location?.address_line1 && <p>{marker.location?.address_line1}</p>}
          {marker.location?.address_line2 && <p>{marker.location?.address_line2}</p>}
          <p>
            {marker.location?.locality}, {marker.location?.administrative_area} {marker.location?.postal_code}
          </p>
        </MarkerBubble>
      )}
    </MarkerContainer>
  );
};

const Map: React.FC<IProps> = ({ markers, center, zoom }) => {
  const defaultCenter = useRef(center?.lat && center?.lng ? { lat: center.lat, lng: center.lng } : undefined);

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GMAP_KEY! }}
        defaultCenter={defaultCenter.current}
        defaultZoom={zoom}
        options={{ styles }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {markers.map((marker) =>
          marker ? (
            <Marker
              key={marker.position?.lat + "" + marker.position?.lng}
              lat={marker.position?.lat}
              lng={marker.position?.lng}
              {...marker}
            />
          ) : null
        )}
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
