import { useLayoutEffect, useState } from "react";
import { DefaultTheme, useTheme } from "styled-components";
import * as Bp from "styled-breakpoints";

type IQueryType = "up" | "down" | "only";
type IBreakpoint = keyof DefaultTheme["breakpoints"];

/**
 * Returns either `true` or `false` whether the query matches.
 * Returns `null` if we are server-side rendering and have no access to the `window`.
 */
const useMediaQuery = (bp: IBreakpoint, qt: IQueryType = "up") => {
  const theme = useTheme();
  const query = Bp[qt](bp)({ theme }).replace("@media", "");

  const [matches, setMatches] = useState(() => {
    if (typeof window === "undefined" || !window.matchMedia) return null;
    return window.matchMedia(query).matches;
  });

  useLayoutEffect(() => {
    if (typeof window === "undefined" || !window.matchMedia) return;
    const mediaQueryList = window.matchMedia(query);
    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);

    mediaQueryList.addListener(listener);
    setMatches(mediaQueryList.matches);
    return () => window && mediaQueryList.removeListener(listener);
  }, [query]);

  return matches;
};

export default useMediaQuery;
