import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useLocation, useMatch } from "@reach/router";
import { useIntl, IntlProvider } from "react-intl/dist";
import { PrimitiveType } from "intl-messageformat";
import DefaultMessages from "intl/en.json";

export type SupportedLocales = "en" | "es";
export type LocaleMessages = typeof DefaultMessages;
export type LocaleKey = keyof LocaleMessages;

export { useIntl };

export const PathsQuery = graphql`
  query Paths {
    paths: allNodeLanding {
      nodes {
        path {
          alias
        }
        field_translation_page_reference {
          url
        }
      }
    }
  }
`;

export const useTranslatedPagePath = () => {
  const { paths } = useStaticQuery<Gatsby.PathsQuery>(PathsQuery);
  const { pathname } = useLocation();
  const match = useMatch(pathname);

  const uri = match?.uri;

  // Fallback to the same path we currently are
  if (!uri) return pathname;

  // Reference path, eg:
  // /covid-safe -> /es/a-salvo-de-covid
  // /es/a-salvo-de-covid -> /covid-safe
  const refPaths = paths.nodes.find((node) => node.path?.alias === match?.uri)?.field_translation_page_reference;

  if (!refPaths) return pathname;

  return refPaths[0]?.url || pathname;
};

export const useTranslatedHomePagePath = () => {
  const { locale: activeLocale } = useIntl();

  // TODO: using staticQuery twice in a file is not supported!
  // TODO: mv query LP template and save results in global AppContent.
  // const HOME_PATH = "/covid-safe";
  // const { paths } = useStaticQuery<Gatsby.PathsQuery>(PathsQuery);
  // const refPaths = paths.nodes.find((node) => node.path?.alias === HOME_PATH)?.field_translation_page_reference;
  // if (!refPaths) return HOME_PATH;

  if (activeLocale === "es") {
    return "/es/a-salvo-de-covid";
  } else {
    return "/covid-safe";
  }
};

export const useFormatMessage = (): ((id: LocaleKey, values?: Record<string, PrimitiveType>) => string) => {
  const intl = useIntl();
  return (id, values) => intl.formatMessage({ id }, values);
};

type ProviderProps = Omit<React.ComponentProps<typeof IntlProvider>, "messages"> & { messages: LocaleMessages };
const Provider: React.FC<ProviderProps> = (props) => <IntlProvider {...props} />;

export default Provider;
