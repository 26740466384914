import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";

interface IProps {
  title?: string;
  body?: string;
  image?: any;
  backgroundColor?: string;
  link?: string;
  layout?: string;
  noBorder?: boolean;
  wrapTitle?: boolean;
  fillParent?: boolean;
}

const Image = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  width: 100%;

  /* ie11 shame */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-height: 400px;
  }

  & > img {
    display: block;
    max-width: 100%;
  }
`;

const Text = styled.div`
  > h1 {
    font-weight: bold;
  }
`;

const Body = styled.div`
  ${Typography.t16Leitura};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  > ul {
    /* ie11 shame */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      list-style-type: square;
    }

    list-style-position: outside;
    padding-left: 20px;
  }

  > ul > li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  > ul > li:before {
    content: "";
    position: absolute;
    margin-top: 0.4em;
    margin-left: -1em;
    height: 6px;
    width: 6px;
    background-color: ${({ theme }) => theme.palette.anotherBlue};

    /* ie11 shame */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      content: none;
      position: static;
    }
  }
`;

const Container = styled.article<{
  layout: string;
  backgroundColor?: string;
  fillParent?: boolean;
  noBorder?: boolean;
}>`
  display: flex;

  height: ${(props) => props.fillParent && "100%"};
  flex-direction: ${({ layout }) => (layout === "top" ? "column" : layout === "bottom" ? "column-reverse" : "column")};
  justify-content: ${({ layout }) =>
    layout === "top" ? "flex-start" : layout === "bottom" ? "flex-end" : "flex-start"};

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor === "blue" ? theme.palette.anotherLightBlue : theme.palette.white};

  /* ie11 shame */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto !important;
  }

  /* While the blue border is no really visible, */
  /* it makes sure that the Card dimensions are the same for all cards */
  /* Note: dont use box-shadow or outline, because those can be cut off if the parent hides its overflow */
  border: ${({ backgroundColor, noBorder, theme }) =>
    noBorder
      ? undefined
      : backgroundColor === "blue"
      ? `1px solid ${theme.palette.anotherLightBlue}`
      : `1px solid rgba(0, 0, 0, 0.05)`};

  padding-left: 5vw; /* Matches the UI.Wrap offset */
  padding-right: 5vw; /* Matches the UI.Wrap offset */
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};

  ${up("lg")} {
    flex-direction: ${({ layout }) => (layout === "left" ? "row" : layout === "right" ? "row-reverse" : undefined)};
    padding-left: ${({ theme }) => theme.spacing(7)};
    padding-right: ${({ theme }) => theme.spacing(7)};

    padding-top: ${({ layout, theme }) =>
      layout === "top" ? theme.spacing(9) : layout === "no-image" ? theme.spacing(8) : theme.spacing(13)};
    padding-bottom: ${({ layout, theme }) =>
      layout === "top" ? theme.spacing(13) : layout === "no-image" ? theme.spacing(8) : theme.spacing(9)};

    > ${Image} {
      max-width: ${({ layout }) => (layout === "left" || layout === "right") && "none"};
      width: ${({ layout }) => (layout === "left" || layout === "right") && "200%"};
    }
  }
`;

const Card: React.FC<IProps> = ({
  image,
  title,
  body,
  fillParent,
  noBorder,
  backgroundColor,
  wrapTitle = true,
  layout = "top",
}) => {
  return (
    <Container backgroundColor={backgroundColor} layout={layout} fillParent={fillParent} noBorder={!!noBorder}>
      {image && (
        <Image>
          <UI.Image {...image} alt={image?.altName} />
        </Image>
      )}
      <Text>
        {title && (
          <UI.Text as={"h1"} typography={"t20Fakt"} gutter={2} color="anotherBlue" noWrap={!wrapTitle}>
            {title}
          </UI.Text>
        )}
        {body && <Body dangerouslySetInnerHTML={{ __html: body }} />}
      </Text>
    </Container>
  );
};

export default Card;
