import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation, useMatch } from "@reach/router";
import useMediaQuery from "hooks/useMediaQuery";
import { useFormatMessage, useTranslatedPagePath, useTranslatedHomePagePath, useIntl } from "context/Intl";
import { useAppStore } from "context/App";
import * as UI from "components/ui";
import HamburgerIcon from "assets/hamburger.svg";
import CalendarIcon from "assets/calendar.svg";
import SearchBar from "./SearchBar";

interface IProps {}

const _siteLangs = [
  { key: "en", label: "English" },
  { key: "es", label: "Español" },
];

const Header = styled.header`
  background-color: ${({ theme }) => theme.palette.white};
  position: relative;
  z-index: 2;
`;

const MobileNavOverlay = styled.nav`
  background: #000000;
  opacity: 0.7;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
`;

const TopBar = styled.div<{ showBottomBorder?: boolean }>`
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${down("md")} {
    border-bottom: ${({ theme, showBottomBorder = true }) =>
      showBottomBorder && `1px solid ${theme.palette.lightGrey}`};
  }
`;

const DesktopNav = styled(UI.Wrap)<{ showBottomBorder?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.palette.lightGrey};
  border-bottom: ${({ theme, showBottomBorder = true }) => showBottomBorder && `1px solid ${theme.palette.lightGrey}`};

  & ul > li > a {
    display: block;
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

const MobileNav = styled.nav`
  background-color: ${({ theme }) => theme.palette.white};
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  position: absolute;
  width: 100%;

  & ul > li > a {
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
    padding-top: ${({ theme }) => theme.spacing(3)};
    padding-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

const MobileNavCta = styled(UI.Button).attrs({ large: true, fluid: true })`
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const LangLink = styled(UI.Link)<{ isActive?: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
  display: block;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  pointer-events: ${({ isActive }) => (isActive ? "none" : "auto")};
  font-weight: ${(props) => props.isActive && 700};
`;

const DesktopNavLink = styled(UI.Link)<{ isActive?: boolean; showActiveBorder?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.palette.anotherBlue : theme.palette.greyNightmare)};
  position: relative;

  &:after {
    display: ${({ isActive, showActiveBorder = true }) => (!isActive || !showActiveBorder) && "none"};
    content: "";
    background-color: ${({ theme }) => theme.palette.anotherBlue};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
  }
`;

const Underline = styled.div<{ width: number; pos: number }>`
  width: ${({ width }) => width + "px"};
  left: ${({ pos }) => pos + "px"};
  height: 2px;
  background-color: ${({ theme }) => theme.palette["anotherBlue"]};
  position: absolute;
  align-self: flex-end;
  transition: all 0.2s ease-in-out;
  bottom: 0;
`;

const NavLinks = styled.div`
  display: flex;
  width: auto;
  position: relative;
`;

const HamburgerLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
`;

let activeMenu: HTMLDivElement | null = null;

const SiteHeader: React.FC<IProps> = () => {
  const { site: siteData, primaryNav, primaryNavEs, logo } = useStaticQuery<Gatsby.SiteHeaderQuery>(SiteHeaderQuery);

  const { pathname } = useLocation();
  const match = useMatch(pathname);
  const isLg = useMediaQuery("lg");
  const formatMessage = useFormatMessage();
  const { locale } = useIntl();
  const translatedPagePath = useTranslatedPagePath();
  const translatedHomePagePath = useTranslatedHomePagePath();
  const isHomePage = useMemo(() => match && match.path.includes("/covid-safe"), [match]);
  let activeLocale = locale;
  if (pathname.match(/404/)) { // useIntl() doesn't work for 404 page, should get locale manually from path
    activeLocale = pathname.replace(/404/, "").replace(/[\/]/g, "");
    activeLocale = activeLocale ? activeLocale : "en";
  }

  const [underlinePos, setUnderlinePos] = useState(0);
  const [underlineWidth, setUnderlineWidth] = useState(0);

  const { overlayIsVisible, setOverlayIsVisible, mobileNavIsVisible, setMobileNavIsVisible } = useAppStore();

  const toggleNav = () => setMobileNavIsVisible(!mobileNavIsVisible);
  const toggleOverlay = () => setOverlayIsVisible(!overlayIsVisible);

  const primaryNavLinks = useMemo(() => {
    switch (activeLocale) {
      case "es":
        return primaryNavEs.nodes;
      case "en":
      default:
        return primaryNav.nodes;
    }
  }, [activeLocale, primaryNav, primaryNavEs]);

  const drawLine = (event: React.MouseEvent<any, MouseEvent>) => {
    const width = event.currentTarget.getBoundingClientRect().width;
    const pos = event.currentTarget.offsetLeft;
    setUnderlinePos(pos);
    setUnderlineWidth(width);
  };

  const resetLine = () => {
    if (activeMenu) {
      const pos = activeMenu.offsetLeft;
      const width = activeMenu.getBoundingClientRect().width;
      setUnderlineWidth(width);
      setUnderlinePos(pos);
    }
  };

  useEffect(() => {
    setMobileNavIsVisible(false);
    resetLine();
  }, [match?.path, setMobileNavIsVisible]);

  return (
    <>
      {mobileNavIsVisible && <MobileNavOverlay onClick={() => setMobileNavIsVisible(false)} />}
      <Header>
        <UI.Wrap>
          <SearchBar searchBoxOnly={pathname !== "/search/"} />
        </UI.Wrap>
        <TopBar showBottomBorder={!isHomePage}>
          <UI.Wrap>
            <UI.Grid.Row vAlign="center" hAlign="justify">
              <UI.Grid.Col size="min">
                <UI.Link to={translatedHomePagePath}>
                  <img src={logo?.publicURL} alt={siteData?.siteMetadata?.title} width={140} height={24} />
                </UI.Link>
              </UI.Grid.Col>
              <UI.Grid.Col size="min">
                {isLg ? (
                  <UI.Button
                    onClick={toggleOverlay}
                    variant="secondary"
                    title={formatMessage("Request an Appointment")}
                    aria-label={formatMessage("Request an Appointment")}
                  >
                    <UI.Icon label={formatMessage("Request an Appointment")}>
                      <CalendarIcon aria-label="Calendar Icon" />
                    </UI.Icon>
                  </UI.Button>
                ) : (
                  isLg !== null && (
                    <HamburgerLink onClick={toggleNav}>
                      <UI.Icon>
                        <HamburgerIcon />
                      </UI.Icon>
                    </HamburgerLink>
                  )
                )}
              </UI.Grid.Col>
            </UI.Grid.Row>
          </UI.Wrap>
        </TopBar>
        {isLg ? (
          <DesktopNav as="nav" showBottomBorder={!isHomePage} role="navigation" aria-label="Primary desktop navigation">
            <UI.Grid.Row vAlign="center" hAlign="justify">
              <UI.Grid.Col size="max">
                <NavLinks onMouseLeave={resetLine}>
                  <UI.Grid.Row as="ul" colGap={4}>
                    {primaryNavLinks.map(({ link, title }) => (
                      <UI.Grid.Col
                        as="li"
                        key={title}
                        ref={(ref: any) => {
                          if (!!match?.path && link?.uri?.includes(match.path.replace(/\/$/, ""))) activeMenu = ref;
                        }}
                      >
                        <DesktopNavLink to={link?.uri!} showActiveBorder={!isHomePage} onMouseEnter={drawLine}>
                          <UI.Text typography="linkButton">{title}</UI.Text>
                        </DesktopNavLink>
                      </UI.Grid.Col>
                    ))}
                  </UI.Grid.Row>
                  <Underline width={underlineWidth} pos={underlinePos} />
                </NavLinks>
              </UI.Grid.Col>
              <UI.Grid.Col size="min">
                <UI.Grid.Row as="ul" colGap={3}>
                  {_siteLangs.map(({ key, label }) => (
                    <UI.Grid.Col as="li" key={key}>
                      <LangLink
                        to={
                          key === activeLocale
                            ? match?.path || translatedHomePagePath
                            : translatedPagePath || translatedHomePagePath
                        }
                        isActive={key === activeLocale}
                      >
                        <UI.Text typography="t14Fakt" color="greyNightmare">
                          {label}
                        </UI.Text>
                      </LangLink>
                    </UI.Grid.Col>
                  ))}
                </UI.Grid.Row>
              </UI.Grid.Col>
            </UI.Grid.Row>
          </DesktopNav>
        ) : (
          isLg !== null &&
          mobileNavIsVisible && (
            <MobileNav role="navigation" aria-label="Primary mobile navigation">
              <ul>
                {primaryNavLinks.map(({ link, title }) => (
                  <li key={title}>
                    <UI.Link to={link?.uri!}>
                      <UI.Wrap>
                        <UI.Text typography="t20Fakt" color="greyNightmare">
                          {title}
                        </UI.Text>
                      </UI.Wrap>
                    </UI.Link>
                  </li>
                ))}
                <UI.Wrap as="li">
                  <UI.Grid.Row hAlign="justify">
                    {_siteLangs.map(({ key, label }) => (
                      <UI.Grid.Col as="span" key={key}>
                        <LangLink
                          to={
                            key === activeLocale
                              ? match?.path || translatedHomePagePath
                              : translatedPagePath || translatedHomePagePath
                          }
                          isActive={key === activeLocale}
                        >
                          <UI.Text typography="t16Fakt" color="greyNightmare">
                            {label}
                          </UI.Text>
                        </LangLink>
                      </UI.Grid.Col>
                    ))}
                  </UI.Grid.Row>
                </UI.Wrap>
              </ul>
              <MobileNavCta onClick={toggleOverlay}>
                <UI.Icon label={formatMessage("Request an Appointment")}>
                  <CalendarIcon />
                </UI.Icon>
              </MobileNavCta>
            </MobileNav>
          )
        )}
      </Header>
    </>
  );
};

const SiteHeaderQuery = graphql`
  query SiteHeader {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(name: { eq: "logo" }) {
      publicURL
    }
    primaryNav: allMenuLinkContentMain(filter: {enabled: { eq: true }}, sort: {fields: weight, order: ASC}) {
      nodes {
        link {
          uri
        }
        title
      }
    }
    primaryNavEs: allMenuLinkContentMainSp(filter: {enabled: { eq: true }}, sort: {fields: weight, order: ASC}) {
      nodes {
        link {
          uri
        }
        title
      }
    }
  }
`;

export default SiteHeader;
