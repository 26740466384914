import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "styles/mixins";
import { useStaticQuery, graphql } from "gatsby";
import Image from "./Image";

interface IProps {
  content: string | React.ReactChildren;
}

const Container = styled.div`
  display: block;
  width: 100%;
  line-height: ${({ theme }) => theme.spacing(3.5)};
  color: ${({ theme }) => theme.palette.greyNightmare};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  *:last-child {
    margin-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h4 {
    ${Typography.h3Leitura}
    margin-top: ${({ theme }) => theme.spacing(6)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  p,
  ul,
  ol,
  img,
  table {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }

  table, td, tr {
    border: none !important;
  }

  ul,
  ol {
    ${Typography.t18Fakt};

    /* ie11 shame */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      list-style-type: square;
    }

    list-style-position: outside;
    padding-left: 20px;

    > li:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }

  ul {

    li {
      &::before {
        position: absolute;
        margin-top: 0.4em;
        margin-left: -1em;
        content: "";
        height: 6px;
        width: 6px;
        background-color: ${({ theme }) => theme.palette["anotherBlue"]};

        /* ie11 shame */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          content: none;
          position: static;
        }
      }
    }
  }

  em,
  i {
    font-style: normal;
  }

  strong,
  b {
    font-weight: bold;
  }

  ol {
    list-style-type: decimal;
  }

  p {
    ${Typography.t18Fakt};
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
  }
`;

const RichText: React.FC<IProps> = ({ content }) => {
  const { allFileFile } = useStaticQuery<Gatsby.RichTextQuery>(RichTextQuery);

  const parseContent = (c: string) => {
    return ReactHtmlParser(c, {
      transform: function transform(node) {
        if (node.type === "tag" && node.name === "img") {
          const uuid = node.attribs && node.attribs["data-entity-uuid"];

          for (let i = 0; i < allFileFile.edges.length; i++) {
            const { node } = allFileFile.edges[i];

            // Images
            if (node.uuid === uuid && node.localFile) {
              return <Image {...node.localFile} />;
            }

            // TODO: add more UI components?
          }
        }

        return undefined;
      },
    });
  };

  const transformedContent = typeof content === "string" ? parseContent(content) : content;
  return <Container>{transformedContent}</Container>;
};

const RichTextQuery = graphql`
  query RichText {
    allFileFile {
      edges {
        node {
          uuid: drupal_id
          localFile {
            childImageSharp {
              fluid(srcSetBreakpoints: [480, 768, 992, 1200, 1600], maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default RichText;
