type Callback = (param: string) => void;

const callbacks: Callback[] = [];

export const registerExpandable = (callback: Callback) => {
  callbacks.push(callback);
};

export const unregisterExpandable = (callback: Callback) => {
  const index = callbacks.findIndex(callback as any);

  if (index !== -1) {
    callbacks.splice(index, 1);
  }
};

export const onExpandableOpen = (id: string) => {
  callbacks.forEach((callback) => callback(id));
};
