import React from "react";
import GatsbyImage, { FluidObject } from "gatsby-image";

interface IProps extends React.HTMLProps<HTMLImageElement> {
  extension?: string;
  publicURL?: string;
  style?: {};
  alt?: string;
  objFit?: string;
  objPosition?: string;
  childImageSharp?: {
    fluid?: FluidObject;
  };
}

const Image: React.FC<IProps> = ({ childImageSharp, extension, publicURL, style, alt = "" }) => {
  // FIX IE11
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const isIE11 = typeof window !== "undefined" && !!window.MSInputMethodContext && !!(document as any).documentMode;
  const fontFamily = isIE11 ? `"object-fit: cover; object-position: 50% 50%"` : undefined;
  const unsupportedExtensions = ["svg", "gif", "apng"];

  if (!!publicURL && !!extension && unsupportedExtensions.includes(extension)) {
    // TODO: add a more sophisticated lazy load library?
    return <img src={publicURL} alt={alt} loading="lazy" />;
  } else if (childImageSharp?.fluid) {
    return <GatsbyImage imgStyle={{ fontFamily }} style={style} fluid={childImageSharp.fluid} alt={alt} />;
  } else {
    return null;
  }
};

export default Image;
