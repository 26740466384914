import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import createFocusTrap, { FocusTrap } from "lib/focusTrap";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import { useIntl, SupportedLocales } from "context/Intl";
import useMediaQuery from "hooks/useMediaQuery";
import useKeyPress from "hooks/useKeyPress";
import { useAppStore } from "context/App";
import CloseIcon from "assets/close.svg";

interface IProps {}

const content = {
  en: {
    title: "Request an in-person appointment or Montefiore FIRST video visit",
    items: [
      {
        title: "Appointments via Montefiore FIRST",
        text:
          "Start by calling your doctor, who will determine if a video visit is appropriate. If it is, your appointment will be scheduled over the phone. For help finding a doctor, call",
        links: [
          { uri: "tel:1-800-636-6683", title: "1-800-MD-MONTE" },
          { uri: "tel:800-636-6683", title: "800-636-6683" },
        ],
      },
      {
        title: "Appointments via MyChart",
        text: "Registered Montefiore MyChart patients can request an appointment online by logging in.",
        links: [
          { uri: "https://mychart.montefiore.org/MyChart/accesscheck.asp", title: "SIGN UP" },
          { uri: "https://mychart.montefiore.org/MyChart/", title: "PATIENT LOGIN" },
        ],
      },
      {
        title: "Appointments via phone",
        text: "To schedule an in-person visit, call your doctor or",
        links: [
          { uri: "tel:1-800-636-6683", title: "1-800-MD-MONTE" },
          { uri: "tel:800-636-6683", title: "800-636-6683" },
        ],
      },
      {
        title: "Appointments via ZocDoc",
        text: "To schedule an appointment using our partner service ZocDoc visit",
        links: [{ uri: "https://www.zocdoc.com/wl/montefiore/", title: "VISIT ZOCDOC" }],
      },
    ],
  },
  es: {
    title: "Pida una cita en persona o una vídeo visita con Montefiore FIRST",
    items: [
      {
        title: "Citas por Montefiore FIRST",
        text:
          "El primer paso es llamar al médico que le indicará si una vídeo visita es la opción adecuada para usted. Si lo fuera, la cita será concertada previamente por teléfono. Si necesita ayuda para buscar un médico, llame a",
        links: [
          { uri: "tel:1-800-636-6683", title: "1-800-MD-MONTE" },
          { uri: "tel:800-636-6683", title: "800-636-6683" },
        ],
      },
      {
        title: "Citas por Montefiore MyChart",
        text:
          "Los pacientes registrados en Montefiore MyChart pueden pedir una cita en línea mediante el siguiente enlace.",
        links: [
          { uri: "https://mychart.montefiore.org/MyChart/accesscheck.asp", title: "Regístrese" },
          { uri: "https://mychart.montefiore.org/MyChart/", title: "Iniciar sesión" },
        ],
      },
      {
        title: "Citas por teléfono",
        text: "Para programar una visita en persona llame a su médico o",
        links: [
          { uri: "tel:1-800-636-6683", title: "1-800-MD-MONTE" },
          { uri: "tel:800-636-6683", title: "800-636-6683" },
        ],
      },
      {
        title: "Citas con ZocDoc",
        text: "Para hacer una cita con nuestro servicio asociado ZocDoc, visite",
        links: [{ uri: "https://www.zocdoc.com/wl/montefiore/", title: "VISITE ZOCDOC" }],
      },
    ],
  },
};

const Container = styled.div``;

const setOpacity = (show: boolean, closing: boolean) => {
  if (closing) {
    return 0;
  } else if (show) {
    return 0.95;
  } else {
    return 0;
  }
};

const Background = styled.div<{ show: boolean; closing: boolean }>`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  position: fixed;
  top: ${({ show }) => (show ? 0 : "100vh")};
  height: 100%;
  width: 100vw;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ show, closing }) => setOpacity(show, closing)};
  background-color: ${({ theme }) => theme.palette["anotherBlue"]};
  z-index: 1000;

  ${up("lg")} {
    top: 0;
    left: ${({ show }) => (show ? 0 : "100vw")};
  }
`;

const moveIn = keyframes`
  100% { transform: translateX(50vw) }
`;

const moveOut = keyframes`
  0% { transform: translate(50vw) }
  100% { transform: translateX(100vw) }
`;

const moveUp = keyframes`
  0% { transform: translateY(100%); visibility: visible }
  100% { transform: translateY(20%); visibility: visible }
`;

const moveDown = keyframes`
  0% { transform: translateY(20%); visibility: visible }
  100% { transform: translateY(100%); visibility: hidden; }
`;

const moveInAnimation = (show: boolean, closing: boolean) => {
  let anim: any = css``;
  if (closing) {
    anim = css`
      ${moveOut} 0.5s forwards
    `;
  } else if (show) {
    anim = css`
      ${moveIn} 0.5s forwards
    `;
  }
  return anim;
};

const moveUpAnimation = (show: boolean, closing: boolean) => {
  let anim: any = css``;
  if (closing) {
    anim = css`
      ${moveDown} 0.5s forwards
    `;
  } else if (show) {
    anim = css`
      ${moveUp} 0.5s forwards
    `;
  }
  return anim;
};

const Content = styled.div<{ show: boolean; closing: boolean }>`
  background-color: white;
  width: 100vw;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(100%);
  animation: ${({ show, closing }) => moveUpAnimation(show, closing)};
  height: 100%;
  z-index: 1001;
  opacity: 1;
  padding-top: ${({ theme }) => theme.spacing(4)};
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 16px 16px 0 0;

  ${up("lg")} {
    top: 0;
    left: 0;
    transform: translateX(100vw);
    animation: ${({ show, closing }) => moveInAnimation(show, closing)};
    width: 50vw;
    height: 100vh;
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(10)};
  }
`;

const Close = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

const Headline = styled.div`
  text-align: center;
  margin: auto;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.palette["lightGrey"]};
  width: 80%;

  ${up("lg")} {
    padding-bottom: ${({ theme }) => theme.spacing(8)};
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(8)};
  justify-content: space-between;
`;

const ItemTitle = styled.div`
  width: 45%;
`;

const ItemText = styled.div`
  ${up("lg")} {
    width: 45%;
  }
`;

const Links = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${({ theme }) => theme.spacing(2)};

  > a {
    display: inline-block;
    margin-right: ${({ theme }) => theme.spacing(2)};
    line-height: 1;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    font-weight: bold;
  }
`;

const Scroll = styled.div`
  overflow: auto;
  height: 65vh;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${up("lg")} {
    padding-left: 0;
    padding-right: 0;
    height: 75vh;
  }
`;

const SiteOverlay: React.FC<IProps> = () => {
  const isLg = useMediaQuery("lg");
  const { locale: activeLocale } = useIntl();
  const { overlayIsVisible, setOverlayIsVisible } = useAppStore();
  const [isClosing, setIsClosing] = useState(false);
  const pressedEsc = useKeyPress("Escape");
  const focusTrap = useRef<FocusTrap>();
  const $container = useRef<HTMLDivElement>(null);

  const overlayContent = useMemo(() => {
    return content[activeLocale as SupportedLocales];
  }, [activeLocale]);

  const closeOverlay = useCallback(() => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setOverlayIsVisible(false);
    }, 500);
  }, [setOverlayIsVisible]);

  const currentTabIndex = useMemo(() => {
    return overlayIsVisible ? 0 : -1;
  }, [overlayIsVisible]);

  // Setup focus trap for the overlay.
  useEffect(() => {
    if (!$container.current || typeof window === "undefined") return;

    focusTrap.current = createFocusTrap($container.current, {
      initialFocus: $container.current,
      preventScroll: true,
    });

    return () => {
      if (focusTrap.current) focusTrap.current.deactivate();
    };
  }, []);

  // Close overlay on "Esc" key press.
  useEffect(() => {
    if (pressedEsc && overlayIsVisible) closeOverlay();
  }, [pressedEsc, overlayIsVisible, closeOverlay]);

  // Trap focus and hide scrollbar when overlay opens.
  useEffect(() => {
    const html = document.querySelector("html");

    if (typeof window === "undefined" || !html) return;

    if (overlayIsVisible) {
      html.style.overflow = "hidden";
      if (focusTrap.current) focusTrap.current.activate();
    } else {
      if (focusTrap.current) focusTrap.current.deactivate();
      html.style.overflow = "auto";
    }
  }, [overlayIsVisible]);

  return (
    <Container
      ref={$container}
      role="dialog"
      aria-modal="true"
      aria-hidden={overlayIsVisible ? "false" : "true"}
      tabIndex={-1}
    >
      <Background show={overlayIsVisible} onClick={closeOverlay} closing={isClosing} />
      <Content show={overlayIsVisible} closing={isClosing}>
        <Close>
          <UI.Button
            round
            variant={"dark"}
            onClick={closeOverlay}
            title="Close Overlay"
            aria-label="Close Overlay"
            tabIndex={currentTabIndex}
          >
            <CloseIcon aria-label="Close Icon" />
          </UI.Button>
        </Close>
        <Scroll>
          <Headline>
            <UI.Text typography={"h4Leitura"}>{overlayContent.title}</UI.Text>
          </Headline>
          {overlayContent.items.map((item, i) => {
            if (isLg) {
              return (
                <Row key={"or_" + i}>
                  <ItemTitle>
                    <UI.Text typography={"t20Fakt"}>{item.title}</UI.Text>
                  </ItemTitle>
                  <ItemText>
                    <UI.Text typography={"t16Leitura"}>{item.text}</UI.Text>
                    <Links>
                      {item.links[0] && (
                        <UI.Link to={item.links[0].uri} showUnderline={true} tabIndex={currentTabIndex}>
                          <UI.Text typography={"t14Fakt"}>{item.links[0].title}</UI.Text>
                        </UI.Link>
                      )}
                      {item.links[1] && (
                        <UI.Link
                          to={item.links[1].uri}
                          showUnderline={item.links[1].title === "PATIENT LOGIN"}
                          underlineColor={"greyNightmare"}
                          tabIndex={currentTabIndex}
                        >
                          <UI.Text typography={"t14Fakt"}>{item.links[1].title}</UI.Text>
                        </UI.Link>
                      )}
                    </Links>
                  </ItemText>
                </Row>
              );
            } else if (isLg !== null) {
              return (
                <UI.Expandable title={item.title} typography={"t16Fakt"} key={"or_" + i}>
                  <ItemText>
                    <UI.Text typography={"t16Leitura"}>{item.text}</UI.Text>
                    <Links>
                      {item.links[0] && (
                        <UI.Link to={item.links[0].uri} showUnderline={true} tabIndex={currentTabIndex}>
                          <UI.Text typography={"t14Fakt"}>{item.links[0].title}</UI.Text>
                        </UI.Link>
                      )}
                      {item.links[1] && (
                        <UI.Link
                          to={item.links[1].uri}
                          showUnderline={item.links[1].title === "PATIENT LOGIN"}
                          underlineColor={"greyNightmare"}
                          tabIndex={currentTabIndex}
                        >
                          <UI.Text typography={"t14Fakt"}>{item.links[1].title}</UI.Text>
                        </UI.Link>
                      )}
                    </Links>
                  </ItemText>
                </UI.Expandable>
              );
            } else {
              return null;
            }
          })}
        </Scroll>
      </Content>
    </Container>
  );
};

export default SiteOverlay;
