import debounce from "lodash.debounce";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";

type IPosition = {
  x: number | null;
  y: number | null;
};

const useWindowScroll = ({ debounceMs = 100 } = {}) => {
  const isBrowser = typeof window !== "undefined";
  const lastScrollPos = useRef<IPosition | null>(null);

  const getPosition = useCallback(
    () => ({
      x: isBrowser ? window.pageXOffset : null,
      y: isBrowser ? window.pageYOffset : null,
    }),
    [isBrowser]
  );

  const [scrollPosition, setScrollPosition] = useState<IPosition>(getPosition);

  const scrollDirection = useMemo(() => {
    const currentX = scrollPosition.x;
    const currentY = scrollPosition.y;
    const lastX = lastScrollPos.current?.x;
    const lastY = lastScrollPos.current?.y;

    return !isBrowser || lastScrollPos.current === null
      ? null
      : (lastY && currentY && lastY > currentY) || (lastX && currentX && lastX > currentX)
      ? -1
      : 1;
  }, [isBrowser, scrollPosition, lastScrollPos]);

  useEffect(() => {
    if (!isBrowser) return;

    const handleScroll = debounce(() => {
      setScrollPosition(getPosition);
      lastScrollPos.current = getPosition();
    }, debounceMs);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [getPosition, isBrowser, debounceMs]);

  return { scrollPosition, scrollDirection };
};

export default useWindowScroll;
