// @ts-nocheck

// https://www.npmjs.com/package/focus-trap
// https://github.com/davidtheclark/focus-trap/blob/master/index.js
// https://github.com/davidtheclark/focus-trap/issues/85
// TODO: this is currently based on the github master branch.
// This way we can use the feature "preventScroll". As soon as the npm package
// has been updated, we should change the install target in package.json.

import xtend from "xtend";
import tabbable from "tabbable";

/**
 * A DOM node, a selector string (which will be passed to
 * `document.querySelector()` to find the DOM node), or a function that
 * returns a DOM node.
 */
export type FocusTarget = HTMLElement | string | { (): HTMLElement };

export interface Options {
  /**
   * A function that will be called when the focus trap activates.
   */
  onActivate?: () => void;
  /**
   * A function that will be called when the focus trap deactivates.
   */
  onDeactivate?: () => void;
  /**
   * By default, when a focus trap is activated the first element in the
   * focus trap's tab order will receive focus. With this option you can
   * specify a different element to receive that initial focus.
   */
  initialFocus?: FocusTarget;
  /**
   * By default, an error will be thrown if the focus trap contains no
   * elements in its tab order. With this option you can specify a
   * fallback element to programmatically receive focus if no other
   * tabbable elements are found. For example, you may want a popover's
   * `<div>` to receive focus if the popover's content includes no
   * tabbable elements. *Make sure the fallback element has a negative
   * `tabindex` so it can be programmatically focused.*
   */
  fallbackFocus?: FocusTarget;
  /**
   * Default: `true`. If `false`, when the trap is deactivated,
   * focus will *not* return to the element that had focus before activation.
   */
  returnFocusOnDeactivate?: boolean;
  /**
   * By default, focus trap on deactivation will return to the element
   * that was focused before activation.
   */
  setReturnFocus?: FocusTarget;
  /**
   * Default: `true`. If `false`, the `Escape` key will not trigger
   * deactivation of the focus trap. This can be useful if you want
   * to force the user to make a decision instead of allowing an easy
   * way out.
   */
  escapeDeactivates?: boolean;
  /**
   * Default: `false`. If `true`, a click outside the focus trap will
   * deactivate the focus trap and allow the click event to do its thing.
   */
  clickOutsideDeactivates?: boolean;
  /**
   * If set and returns `true`,
   * a click outside the focus trap will not be prevented,
   * even when `clickOutsideDeactivates` is `false`.
   */
  allowOutsideClick?: (event: MouseEvent) => boolean;
  /**
   * By default, focus() will scroll to the element if not in viewport.
   * It can produce unattented effects like scrolling back to the top of a modal.
   * If set to `true`, no scroll will happen.
   */
  preventScroll?: boolean;
}

type ActivateOptions = Pick<Options, "onActivate">;

interface DeactivateOptions extends Pick<Options, "onDeactivate"> {
  returnFocus?: boolean;
}

export interface FocusTrap {
  activate(activateOptions?: ActivateOptions): void;
  deactivate(deactivateOptions?: DeactivateOptions): void;
  pause(): void;
  unpause(): void;
}

let activeFocusDelay!: number;

const activeFocusTraps = (function () {
  const trapQueue = [];

  return {
    activateTrap: function (trap) {
      if (trapQueue.length > 0) {
        const activeTrap = trapQueue[trapQueue.length - 1];
        if (activeTrap !== trap) {
          activeTrap.pause();
        }
      }

      const trapIndex = trapQueue.indexOf(trap);
      if (trapIndex === -1) {
        trapQueue.push(trap);
      } else {
        // move this existing trap to the front of the queue
        trapQueue.splice(trapIndex, 1);
        trapQueue.push(trap);
      }
    },

    deactivateTrap: function (trap) {
      const trapIndex = trapQueue.indexOf(trap);
      if (trapIndex !== -1) {
        trapQueue.splice(trapIndex, 1);
      }

      if (trapQueue.length > 0) {
        trapQueue[trapQueue.length - 1].unpause();
      }
    },
  };
})();

function focusTrap(element, userOptions) {
  const doc = document;
  const container = typeof element === "string" ? doc.querySelector(element) : element;

  const config = xtend(
    {
      returnFocusOnDeactivate: true,
      escapeDeactivates: true,
    },
    userOptions
  );

  const state = {
    firstTabbableNode: null,
    lastTabbableNode: null,
    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: false,
    paused: false,
  };

  const trap = {
    activate: activate,
    deactivate: deactivate,
    pause: pause,
    unpause: unpause,
  };

  return trap;

  function activate(activateOptions) {
    if (state.active) return;

    updateTabbableNodes();

    state.active = true;
    state.paused = false;
    state.nodeFocusedBeforeActivation = doc.activeElement;

    const onActivate = activateOptions && activateOptions.onActivate ? activateOptions.onActivate : config.onActivate;
    if (onActivate) {
      onActivate();
    }

    addListeners();
    return trap;
  }

  function deactivate(deactivateOptions) {
    if (!state.active) return;

    clearTimeout(activeFocusDelay);

    removeListeners();
    state.active = false;
    state.paused = false;

    activeFocusTraps.deactivateTrap(trap);

    const onDeactivate =
      deactivateOptions && deactivateOptions.onDeactivate !== undefined
        ? deactivateOptions.onDeactivate
        : config.onDeactivate;
    if (onDeactivate) {
      onDeactivate();
    }

    const returnFocus =
      deactivateOptions && deactivateOptions.returnFocus !== undefined
        ? deactivateOptions.returnFocus
        : config.returnFocusOnDeactivate;
    if (returnFocus) {
      delay(function () {
        tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation));
      });
    }

    return trap;
  }

  function pause() {
    if (state.paused || !state.active) return;
    state.paused = true;
    removeListeners();
  }

  function unpause() {
    if (!state.paused || !state.active) return;
    state.paused = false;
    updateTabbableNodes();
    addListeners();
  }

  function addListeners() {
    if (!state.active) return;

    // There can be only one listening focus trap at a time
    activeFocusTraps.activateTrap(trap);

    // Delay ensures that the focused element doesn't capture the event
    // that caused the focus trap activation.
    activeFocusDelay = delay(function () {
      tryFocus(getInitialFocusNode());
    });

    doc.addEventListener("focusin", checkFocusIn, true);
    doc.addEventListener("mousedown", checkPointerDown, {
      capture: true,
      passive: false,
    });
    doc.addEventListener("touchstart", checkPointerDown, {
      capture: true,
      passive: false,
    });
    doc.addEventListener("click", checkClick, {
      capture: true,
      passive: false,
    });
    doc.addEventListener("keydown", checkKey, {
      capture: true,
      passive: false,
    });

    return trap;
  }

  function removeListeners() {
    if (!state.active) return;

    doc.removeEventListener("focusin", checkFocusIn, true);
    doc.removeEventListener("mousedown", checkPointerDown, true);
    doc.removeEventListener("touchstart", checkPointerDown, true);
    doc.removeEventListener("click", checkClick, true);
    doc.removeEventListener("keydown", checkKey, true);

    return trap;
  }

  function getNodeForOption(optionName) {
    const optionValue = config[optionName];
    let node = optionValue;
    if (!optionValue) {
      return null;
    }
    if (typeof optionValue === "string") {
      node = doc.querySelector(optionValue);
      if (!node) {
        throw new Error("`" + optionName + "` refers to no known node");
      }
    }
    if (typeof optionValue === "function") {
      node = optionValue();
      if (!node) {
        throw new Error("`" + optionName + "` did not return a node");
      }
    }
    return node;
  }

  function getInitialFocusNode() {
    let node;
    if (getNodeForOption("initialFocus") !== null) {
      node = getNodeForOption("initialFocus");
    } else if (container.contains(doc.activeElement)) {
      node = doc.activeElement;
    } else {
      node = state.firstTabbableNode || getNodeForOption("fallbackFocus");
    }

    if (!node) {
      throw new Error("Your focus-trap needs to have at least one focusable element");
    }

    return node;
  }

  function getReturnFocusNode(previousActiveElement) {
    const node = getNodeForOption("setReturnFocus");
    return node ? node : previousActiveElement;
  }

  // This needs to be done on mousedown and touchstart instead of click
  // so that it precedes the focus event.
  function checkPointerDown(e) {
    if (container.contains(e.target)) return;
    if (config.clickOutsideDeactivates) {
      deactivate({
        returnFocus: !tabbable.isFocusable(e.target),
      });
      return;
    }
    // This is needed for mobile devices.
    // (If we'll only let `click` events through,
    // then on mobile they will be blocked anyways if `touchstart` is blocked.)
    if (config.allowOutsideClick && config.allowOutsideClick(e)) {
      return;
    }
    e.preventDefault();
  }

  // In case focus escapes the trap for some strange reason, pull it back in.
  function checkFocusIn(e) {
    // In Firefox when you Tab out of an iframe the Document is briefly focused.
    if (container.contains(e.target) || e.target instanceof Document) {
      return;
    }
    e.stopImmediatePropagation();
    tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode());
  }

  function checkKey(e) {
    if (config.escapeDeactivates !== false && isEscapeEvent(e)) {
      e.preventDefault();
      deactivate();
      return;
    }
    if (isTabEvent(e)) {
      checkTab(e);
      return;
    }
  }

  // Hijack Tab events on the first and last focusable nodes of the trap,
  // in order to prevent focus from escaping. If it escapes for even a
  // moment it can end up scrolling the page and causing confusion so we
  // kind of need to capture the action at the keydown phase.
  function checkTab(e) {
    updateTabbableNodes();
    if (e.shiftKey && e.target === state.firstTabbableNode) {
      e.preventDefault();
      tryFocus(state.lastTabbableNode);
      return;
    }
    if (!e.shiftKey && e.target === state.lastTabbableNode) {
      e.preventDefault();
      tryFocus(state.firstTabbableNode);
      return;
    }
  }

  function checkClick(e) {
    if (config.clickOutsideDeactivates) return;
    if (container.contains(e.target)) return;
    if (config.allowOutsideClick && config.allowOutsideClick(e)) {
      return;
    }
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  function updateTabbableNodes() {
    const tabbableNodes = tabbable(container);
    state.firstTabbableNode = tabbableNodes[0] || getInitialFocusNode();
    state.lastTabbableNode = tabbableNodes[tabbableNodes.length - 1] || getInitialFocusNode();
  }

  function tryFocus(node: HTMLElement) {
    if (node === doc.activeElement) return;
    if (!node || !node.focus) {
      tryFocus(getInitialFocusNode());
      return;
    }
    node.focus({ preventScroll: userOptions.preventScroll });
    state.mostRecentlyFocusedNode = node;
    if (isSelectableInput(node)) {
      node.select();
    }
  }
}

function isSelectableInput(node: HTMLInputElement) {
  return node.tagName && node.tagName.toLowerCase() === "input" && typeof node.select === "function";
}

function isEscapeEvent(e: KeyboardEvent) {
  return e.key === "Escape" || e.key === "Esc" || e.keyCode === 27;
}

function isTabEvent(e: KeyboardEvent) {
  return e.key === "Tab" || e.keyCode === 9;
}

function delay(fn: () => void) {
  return setTimeout(fn, 0);
}

export default focusTrap;
