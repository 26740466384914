import { createGlobalStyle } from "styled-components";
import { Typography } from "styles/mixins";

export default createGlobalStyle`
  *::selection {
    background-color: ${({ theme }) => theme.palette.greyNightmare};
    color: ${({ theme }) => theme.palette.white};
  }

  html {
    cursor: default;
    font-size: 62.5%;
    overflow-y: scroll;
    overscroll-behavior: none;
    scroll-behavior: smooth;
  }

  body {
    ${Typography.t16Fakt};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.greyNightmare};
  }

  a:focus,
  button:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  #airbud-root button[class^="close"],
  #airbud-root button [class^="notificationCircle"] {
    text-align: center;
  }

  #airbud-root div[class^="widgetIconContainer"] {
    z-index: 9;
  }
  
  .gsc-tabHeader {
    border-color: black!important;
    color: black!important;
    //padding-top: 24px!important;
    //padding-bottom: 24px!important;
    padding: 24px!important;
    font-size: 14px!important;
    font-weight: normal!important;
  }
  
  .gsc-result-info {
    padding: 24px 0!important;
  }
  
  .gsc-orderby-container {
    //display: inline-flex!important;
    //justify-content: flex-end!important;
    //align-items: center!important;
    vertical-align: middle!important;
    
    .gsc-option-menu-container {
      
      .gsc-selected-option-container {
        background-color: white!important;
      }
    }
  }
  
  .gsc-wrapper {
    max-width: 800px;
    width: 100%;
    margin: auto;
    padding-top: ${({theme}) => theme.spacing(5)}!important;
    
    .gsc-result {
      padding: ${({theme}) => theme.spacing(4)} 0!important;
      border: 0!important;
      border-bottom: 1px solid!important;
      border-color: ${({theme}) => theme.palette.lightGrey}!important;
      
      .gs-title {
        font-size: 24px!important;
        color: ${({theme}) => theme.palette.anotherBlue}!important;
        
        b {
          font-size: 24px!important;
          color: ${({theme}) => theme.palette.anotherBlue}!important;
        }
      }
      
      .gsc-url-top {
        margin-bottom: ${({theme}) => theme.spacing(2)}!important;
        font-size: 18px!important;
        line-height: 28px!important;
        > div {
          color: ${({theme}) => theme.palette.someGrey}!important;
        }
      }
      
      .gs-snippet {
        font-size: 18px!important;
        line-height: 28px!important;
      }
      
      .gs-image-box {
        display: none;
      }
    }
  }
  
  
  .gsc-search-box {
    margin: 0!important;
    
    
    
    tr { position: relative; }
    
    .gsc-input {
      padding: 0!important;
      border: 0!important;
      
      .gsc-input-box {
        border: 0!important;
        width: 300px;
        
        input {
          height: 62px!important;
          
          &:focus {
            border-bottom: 1px solid ${({ theme }) => theme.palette.anotherBlue}!important;
          }
        }
        
        table {
          border: 0;
          
          td {
            border: 0!important;
            padding: 0!important;
          }
        }
      }
    }
    .gsc-search-button {
      border: 0;
      position: absolute!important;
      width: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      
      button {
        background-color: transparent;
        border: 0;
        background-image: none;
        padding: 0!important;
      
        &:focus, &:active, &:hover {
          border: 0;
          outline: none;
          background-image: none;
          background-color: transparent;
        }
        
        svg {
          path {
            fill: #153764;
          }
        }
      }
    }
    
    .gsib_b {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
     
    }
  }
`;
