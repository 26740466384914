import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { useAppStore } from "context/App";
import { useFormatMessage } from "context/Intl";
import { Link as GatsbyLink } from "gatsby";

interface IStyleProps {
  bold?: boolean;
  showUnderline?: boolean;
  underlineColor?: keyof DefaultTheme["palette"];
}

interface IProps extends IStyleProps, React.HTMLProps<HTMLAnchorElement> {
  to: string;
}

const PatientRestartDomains = [
  "https://covidsafecare.montefiore.org",
  "https://covidsafecare-edit.montefiore.org",
  "http://dev.covidsafecare-edit.montefiore.org",
  "http://stage.covidsafecare-edit.montefiore.org",
  "http://patientrestart27kfksim4c.devcloud.acquia-sites.com",
  "http://patientrestartbovrfhkyek.devcloud.acquia-sites.com",
  "http://patientrestart73fnw8tdkr.devcloud.acquia-sites.com",
  "http://localhost:8000",
] as const;

const isInternalLink = (href: string) => {
  if(href == null) {
    return false;
  }

  return (
    href.indexOf("www") === -1 &&
    href.indexOf("http") === -1 &&
    href.indexOf("tel:") === -1 &&
    href.indexOf("mailto:") === -1 &&
    href.startsWith("#") === false
  );
};

const StyledLink = styled.div.withConfig<IStyleProps>({
  shouldForwardProp: (prop, defaultValidatorFn) => !["showUnderline"].includes(prop) && defaultValidatorFn(prop),
})`
  ${({ showUnderline, bold = true, theme, underlineColor = "fuchsia" }) =>
    showUnderline &&
    `
      font-weight: ${bold ? "bold" : "normal"};
      padding-bottom: 2px;
      border-bottom: 2px solid ${theme.palette[underlineColor]};
      transition: border-bottom-color 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
      will-change: border-bottom-color;

      &:hover {
        border-bottom-color: ${theme.palette.greyNightmare};
      }
  `}
`;

const Link: React.FC<IProps> = ({ to, children, ...props }) => {
  var baseDomain = null;
  if (typeof to !== 'undefined') {
    baseDomain = PatientRestartDomains.find((d) => to.startsWith(d));
  }
  const path = baseDomain ? to.replace(baseDomain, "") : null;
  const { overlayIsVisible, setOverlayIsVisible } = useAppStore();
  const formatMessage = useFormatMessage();
  const toggleOverlay = () => setOverlayIsVisible(!overlayIsVisible);

  if (isInternalLink(to) || (path && isInternalLink(path))) {
    if (path?.includes("/request-appointment") || to?.includes("/request-appointment")) {
      return (
        <StyledLink
          // @ts-ignore
          as="button"
          onClick={toggleOverlay}
          title={formatMessage("Request an Appointment")}
          aria-label={formatMessage("Request an Appointment")}
          {...props}
        >
          {children}
        </StyledLink>
      );
    }

    return (
      // @ts-ignore
      <StyledLink as={GatsbyLink} to={path || to} {...props}>
        {children}
      </StyledLink>
    );
  }

  return (
    // @ts-ignore
    <StyledLink as="a" href={to} {...props}>
      {children}
    </StyledLink>
  );
};

export default Link;
