import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";

interface IProps {
  image: any;
  title: string;
  links: { link: string; name: string; title: string }[];
}

const Container = styled.article`
  display: block;
  height: 100%;
`;

const Image = styled.div`
  > .gatsby-image-wrapper {
    height: 100%;
  }

  ${up("lg")} {
    height: 54rem;
  }
`;

const LinkElement = styled.div`
  ${Typography.t14Fakt}

  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: bold;
`;

const CardCTA: React.FC<IProps> = ({ image, title, links }) => {
  return (
    <Container>
      {image && (
        <Image>
          <UI.Image style={{ height: "100%" }} {...image} alt={image?.altName} />
        </Image>
      )}
      <Title>
        <UI.Text as="h1" typography={"t20Fakt"} color={"greyNightmare"}>
          {links && links[0] ? <UI.Link to={links[0].link}>{title}</UI.Link> : { title }}
        </UI.Text>
      </Title>
      {links.map((link, i) => {
        return link?.title && link?.link ? (
          <LinkElement key={title + i}>
            <UI.Link to={link.link} showUnderline={true} bold={false}>
              {link.title}
            </UI.Link>
          </LinkElement>
        ) : null;
      })}
    </Container>
  );
};

export default CardCTA;
