import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  // Returns a factor of 8px.
  spacing: (step: number) => `${step * 0.8}rem`,
  fonts: {
    // prettier-ignore
    leitura: "'Leitura News', Georgia, Cambria, 'Times New Roman', Times, serif",
    // prettier-ignore
    fakt: "Arial, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
  },
  palette: {
    white: "#FFFFFF",
    blueEinstein: "#4E79BD",
    greyNightmare: "#313335",
    greyNightmareAlt: "#000000",
    fuchsia: "#C4006B",
    fuchsiaAlt: "#A00058",
    lightGrey: "#F5F5F5",
    grey: "#D0D7E0",
    rectangleBg: "#F5F3F7",
    plainBlack: "#000000",
    lineGrey: "#ECECEC",
    underlinePink: "#F00C72",
    anotherBlue: "#153764",
    anotherBlueAlt: "#0E2C54",
    anotherLightBlue: "#F1F7FB",
    someGrey: "#767676",
    buttonBlue: "#003C7E",
  },
  breakpoints: {
    sm: 1,
    md: 768,
    lg: 992,
    xl: 1280,
    max: 1600,
  },
} as const;

export default theme;
