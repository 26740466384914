import styled, { css } from "styled-components";
import { stlyedBreakPoints, IResponsiveProp } from "styles/utils";

type IColSize = number | "hidden" | "min" | "max";
type IVAlign = "top" | "bottom" | "center" | "stretch";
type IHAlign = "left" | "right" | "center" | "justify" | "justify-center";
type IDirection = "row" | "column";
type IColAlign = "center" | "flex-end" | "flex-start" | "stretch";

interface IRowProps {
  colGap?: IResponsiveProp<number>;
  rowGap?: IResponsiveProp<number>;
  noWrap?: IResponsiveProp<boolean>;
  vAlign?: IResponsiveProp<IVAlign>;
  hAlign?: IResponsiveProp<IHAlign>;
  direction?: IResponsiveProp<IDirection>;
}

interface IColProps {
  size?: IResponsiveProp<IColSize>;
  align?: IResponsiveProp<IColAlign>;
}

const ColSize = stlyedBreakPoints<IColProps, IColSize>("size");
const ColAlign = stlyedBreakPoints<IColProps, IColAlign>("align");

export const Col = styled.div.withConfig<IColProps>({
  shouldForwardProp: (prop, defaultValidatorFn) => !["size", "align"].includes(prop) && defaultValidatorFn(prop),
})`
  flex: 0 0 auto;
  flex-direction: column;

  ${ColAlign((align) => {
    return (
      align &&
      css`
        align-self: ${align};
      `
    );
  })}

  ${ColSize((size) => {
    switch (size) {
      case "max":
        return css`
          display: flex;
          flex-grow: 1;
          flex-basis: auto;
          width: auto;
          max-width: 100%;
        `;

      case "min":
        return css`
          display: flex;
          flex-grow: 0;
          flex-basis: auto;
          width: auto;
          max-width: none;
        `;

      case "hidden":
        return css`
          display: none;
        `;

      default:
        const pct = Math.round(size * 100 * 10000) / 10000;

        return css`
          display: flex;
          flex-basis: ${pct}%;
          max-width: ${pct}%;
        `;
    }
  })}
`;

const VAlign = stlyedBreakPoints<IRowProps, IVAlign>("vAlign");
const HAlign = stlyedBreakPoints<IRowProps, IHAlign>("hAlign");
const NoWrap = stlyedBreakPoints<IRowProps, boolean>("noWrap");
const ColGap = stlyedBreakPoints<IRowProps, number>("colGap");
const RowGap = stlyedBreakPoints<IRowProps, number>("rowGap");
const Direction = stlyedBreakPoints<IRowProps, string>("direction");

export const Row = styled.div.withConfig<IRowProps>({
  shouldForwardProp: (prop, defaultValidatorFn) => !["direction"].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;

  ${Direction((direction) => {
    return css`
      flex-direction: ${direction};
    `;
  })}

  ${NoWrap((noWrap) => {
    return css`
      flex-wrap: ${noWrap ? "nowrap" : "wrap"};
    `;
  })}

  ${ColGap((gap) => {
    return css`
      margin-left: ${({ theme }) => (!!gap ? `calc(${theme.spacing(gap)} * -0.5)` : "0")};
      margin-right: ${({ theme }) => (!!gap ? `calc(${theme.spacing(gap)} * -0.5)` : "0")};

      ${`> ${Col}`} {
        padding-left: ${({ theme }) => (!!gap ? `calc(${theme.spacing(gap)} / 2)` : "0")};
        padding-right: ${({ theme }) => (!!gap ? `calc(${theme.spacing(gap)} / 2)` : "0")};
      }
    `;
  })}

  ${RowGap((gap) => {
    return css`
      ${`> ${Col} + ${Col}`} {
        margin-top: ${({ theme }) => (!!gap ? theme.spacing(gap) : "0")};
      }
    `;
  })}

  ${HAlign((hAlign) => {
    switch (hAlign) {
      case "left":
        return css`
          justify-content: flex-start;
        `;

      case "right":
        return css`
          justify-content: flex-end;
        `;

      case "center":
        return css`
          justify-content: center;
        `;

      case "justify-center":
        return css`
          justify-content: space-around;
        `;

      case "justify":
        return css`
          justify-content: space-between;
        `;

      default:
        return null;
    }
  })}

  ${VAlign((vAlign) => {
    switch (vAlign) {
      case "top":
        return css`
          align-items: flex-start;
        `;

      case "bottom":
        return css`
          align-items: flex-end;
        `;

      case "center":
        return css`
          align-items: center;
        `;

      case "stretch":
        return css`
          align-items: stretch;
        `;

      default:
        return null;
    }
  })}
`;
