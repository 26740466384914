import { useState, useRef, useEffect } from "react";

const useClickOutside = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);
  const [hasClickedOutside, setHasClickedOutside] = useState(false);

  const handleEvent = (e: Event) => {
    if (!ref?.current) return;
    setHasClickedOutside(!ref.current.contains(e.target as Node));
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (window.PointerEvent) {
      window.addEventListener("pointerdown", handleEvent);
    } else {
      window.addEventListener("mousedown", handleEvent);
      window.addEventListener("touchstart", handleEvent);
    }

    return () => {
      if (window.PointerEvent) {
        window.removeEventListener("pointerdown", handleEvent);
      } else {
        window.removeEventListener("mousedown", handleEvent);
        window.removeEventListener("touchstart", handleEvent);
      }
    };
  }, []);

  return [ref, hasClickedOutside] as const;
};

export default useClickOutside;
