import styled, { css } from "styled-components";
import { stlyedBreakPoints, IResponsiveProp } from "styles/utils";

interface IProps {
  fluid?: IResponsiveProp<boolean>;
}

const Fluid = stlyedBreakPoints<IProps, boolean>("fluid");

const Wrap = styled.div<IProps>`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.breakpoints.max}px;
  width: 90vw;
  overflow: hidden;
  position: relative;

  ${Fluid((isFluid) => {
    return css`
      width: ${isFluid ? "100%" : "90vw"};
    `;
  })}
`;

export default Wrap;
