import React, { useState, useEffect, useRef } from "react";
import * as UI from "components/ui";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Typography } from "styles/mixins";
import { onExpandableOpen, registerExpandable, unregisterExpandable } from "lib/expandableHelper";
import ChevronLeft from "assets/chevron-left.svg";
import useMediaQuery from "../../hooks/useMediaQuery";

interface IProps {
  title: string;
  id?: string;
  typography?: keyof typeof Typography;
  managed?: string;
  scrollOffset?: number;
}

const Container = styled.article`
  border-bottom: 1px solid ${({ theme }) => theme.palette["lightGrey"]};

  ${up("lg")} {
    padding-top: 0;
    padding-right: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(15)};
  }
`;

const Title = styled.h1`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};

  ${up("lg")} {
    padding-top: ${({ theme }) => theme.spacing(8.5)};
    padding-bottom: ${({ theme }) => theme.spacing(8.5)};
  }
`;

const Content = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? "auto" : 0)};
  opacity: ${({ open }) => (open ? 1 : 0)};
  padding-bottom: ${({ theme, open }) => open && theme.spacing(3)};
  transition: height 0.3s ease-in, opacity 0.5s ease-in;
  overflow: hidden;

  ${up("lg")} {
    padding-bottom: ${({ theme, open }) => open && theme.spacing(8.5)};
  }
`;

const TitleText = styled(UI.Text)`
  display: block;
  font-weight: bold;
  // max-width: 90%;
  max-width: 700px;
  width: 100%;
`;

const Expandable: React.FC<IProps> = ({ typography, title, children, id, managed, scrollOffset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLElement>(null);
  const chevronRotation = isOpen ? "rotateZ(90deg)" : "rotate(-90deg)";

  const onClick = () => {
    if (!isOpen) {
      onExpandableOpen(id + "");
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && containerRef?.current && scrollOffset) {
      const y = containerRef.current!.getBoundingClientRect().top + window.pageYOffset - scrollOffset;
      const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;

      // IE11 does not support scrollTo options.
      if (supportsNativeSmoothScroll) {
        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        window.scrollTo(0, y);
      }
    }
  }, [isOpen]);

  const callback = (expId: string) => {
    if (expId !== managed) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (managed) {
      registerExpandable(callback);
    }
    return () => {
      if (managed) {
        unregisterExpandable(callback);
      }
    };
  }, []);

  return (
    <Container id={id} ref={containerRef}>
      <Title onClick={onClick}>
        <TitleText typography={typography || "t20Fakt"} color={"anotherBlue"}>
          {title}
        </TitleText>
        <UI.Icon>
          <ChevronLeft style={{ transition: "all 0.3s ease-in", transform: chevronRotation }} />
        </UI.Icon>
      </Title>
      {isOpen && <Content open={isOpen}>{children}</Content>}
    </Container>
  );
};

export default Expandable;
