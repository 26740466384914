import { css } from "styled-components";
import { up } from "styled-breakpoints";

export const Typography = {
  h1Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 3.6rem; /* = 36px */
    line-height: 1.111; /* = 40px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 8.8rem; /* = 88px */
      line-height: 1; /* = 88px */
    }
  `,
  h1LeituraSmall: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 3.6rem; /* = 36px */
    line-height: 1.111; /* = 40px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 6.4rem; /* = 64px */
      line-height: 1; /* = 64px */
    }
  `,
  h2Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 2.6rem; /* = 26px */
    line-height: 1.235; /* = 32px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 5.1rem; /* = 51px */
      line-height: 1.05; /* = 54px */
    }
  `,
  h3Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 2.8rem; /* = 28px */
    line-height: 1.225; /* = 34px */
    letter-spacing: -0.145714px;

    ${up("md")} {
      font-size: 3.2rem; /* = 32px */
      line-height: 1.2; /* = 38px */
    }

    ${up("lg")} {
      font-size: 4.4rem; /* = 44px */
      line-height: 1.13; /* = 50px */
    }
  `,
  h4Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 2.8rem; /* = 28px */
    line-height: 1.215; /* = 34px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 2.8rem; /* = 28px */
      line-height: 1.215; /* = 34px */
    }
  `,
  t24Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 1.6rem; /* = 16px */
    line-height: 1.375; /* = 22px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 2.4rem; /* = 24px */
      line-height: 1.3333; /* = 32px */
    }
  `,
  t16Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 1.6rem; /* = 16px */
    line-height: 1.375; /* = 22px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 1.6rem; /* = 16px */
      line-height: 1.375; /* = 22px */
    }
  `,
  t14Leitura: css`
    font-family: ${({ theme }) => theme.fonts.leitura};
    font-size: 1.4rem; /* = 14px */
    line-height: 1.07; /* = 15px */
    letter-spacing: -0.145714px;

    ${up("lg")} {
      font-size: 1.4rem; /* = 14px */
      line-height: 1.07; /* = 15px */
    }
  `,
  h2Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 5.6rem; /* = 56px */
    line-height: 0.825; /* = 46px */

    ${up("lg")} {
      font-size: 5.6rem; /* = 56px */
      line-height: 0.825; /* = 46px */
    }
  `,
  h3Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 4rem; /* = 40px */
    line-height: 1.1; /* = 44px */

    ${up("lg")} {
      font-size: 4rem; /* = 40px */
      line-height: 1.1; /* = 44px */
    }
  `,
  h4Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 3.2rem; /* = 32px */
    line-height: 1.1875; /* = 38px */

    ${up("lg")} {
      font-size: 3.2rem; /* = 32px */
      line-height: 1.1875; /* = 38px */
    }
  `,
  h5Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 2.6rem; /* = 26px */
    line-height: 1.235; /* = 32px */

    ${up("lg")} {
      font-size: 2.6rem; /* = 26px */
      line-height: 1.235; /* = 32px */
    }
  `,
  t28Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 6.4rem; /* = 64px */
    line-height: 1.15; /* = 74px */

    ${up("lg")} {
      font-size: 6.4rem; /* = 64px */
      line-height: 1.15; /* = 74px */
    }
  `,
  t20Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 2rem; /* = 20px */
    line-height: 1.3; /* = 26px */

    ${up("lg")} {
      font-size: 2rem; /* = 20px */
      line-height: 1.3; /* = 26px */
    }
  `,
  t18Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.8rem; /* = 18px */
    line-height: 1.555; /* = 28px */

    ${up("lg")} {
      font-size: 1.8rem; /* = 18px */
      line-height: 1.555; /* = 28px */
    }
  `,
  t16Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.6rem; /* = 16px */
    line-height: 1.375; /* = 22px */

    ${up("lg")} {
      font-size: 1.6rem; /* = 16px */
      line-height: 1.375; /* = 22px */
    }
  `,
  t15Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.5rem; /* = 15px */
    line-height: 1.46666; /* = 22px */

    ${up("lg")} {
      font-size: 1.5rem; /* = 15px */
      line-height: 1.46666; /* = 22px */
    }
  `,
  t14Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.4rem; /* = 14px */
    line-height: 1; /* = 14px */

    ${up("lg")} {
      font-size: 1.4rem; /* = 14px */
      line-height: 1; /* = 14px */
    }
  `,
  t12Fakt: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.2rem; /* = 12px */
    line-height: 1.3333; /* = 16px */

    ${up("lg")} {
      font-size: 1.2rem; /* = 12px */
      line-height: 1.3333; /* = 16px */
    }
  `,
  linkButton: css`
    font-family: ${({ theme }) => theme.fonts.fakt};
    font-size: 1.6rem; /* = 16px */
    line-height: 1.25; /* = 20px */

    ${up("lg")} {
      font-size: 1.6rem; /* = 16px */
      line-height: 1.25; /* = 20px */
    }
  `,
} as const;
