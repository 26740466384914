import React, { useEffect, useState, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { WindowLocation } from "@reach/router";
import theme from "styles/theme";
import GlobalStyles from "styles/Global";
import SiteHead from "components/SiteHead";
import SiteOverlay from "components/SiteOverlay";
import SiteAlerts from "components/SiteAlerts";
import SiteHeader from "components/SiteHeader";
import SiteFooter from "components/SiteFooter";
import AppProvider from "context/App";
import IntlProvider, { LocaleMessages, SupportedLocales } from "context/Intl";
import EnMessages from "intl/en.json";
import EsMessages from "intl/es.json";

interface IProps {
  langCode: SupportedLocales;
  location: WindowLocation;
}

const Messages = {
  en: EnMessages,
  es: EsMessages,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SiteMain = styled.main`
  flex: 1;
  -ms-flex: none; /* FIX IE11 */
`;

const Layout: React.FC<IProps> = ({ children, location, langCode }) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<LocaleMessages>(Messages[langCode]);

  useEffect(() => {
    setMessages(Messages[langCode]);
  }, [langCode]);

  return (
    <IntlProvider locale={langCode} messages={messages} defaultLocale="en">
      <ThemeProvider theme={theme}>
        <AppProvider>
          <div id="alert-target-container" />
          <Wrapper ref={$wrapper}>
            {/* <SiteAlerts /> */}
            <SiteHeader />
            <GlobalStyles />
            <SiteHead pathname={location.pathname} />
            <SiteMain>{children}</SiteMain>
            <SiteFooter />
            <SiteOverlay />
          </Wrapper>
        </AppProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default Layout;
