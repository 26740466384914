import React from "react";
import styled, { DefaultTheme } from "styled-components";

interface IStyleProps {
  color?: keyof DefaultTheme["palette"];
}

interface IProps extends IStyleProps {
  label?: string;
}

const IconContainer = styled.span<IStyleProps>`
  color: ${({ theme, color = "white" }) => theme.palette[color]};
  /* line-height: 1; */
  display: inline-flex;
  align-content: center;

  & > span {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Icon: React.FC<IProps> = ({ children, label, ...props }) => (
  <IconContainer {...props}>
    {children}
    {label && <span>{label}</span>}
  </IconContainer>
);

export default Icon;
