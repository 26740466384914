import styled, { css } from "styled-components";
import { up } from "styled-breakpoints";
import { styledMap } from "styles/utils";
import { Typography } from "styles/mixins";

type IVariant = "primary" | "secondary" | "dark" | "grey";

interface IProps {
  large?: boolean;
  fluid?: boolean;
  round?: boolean;
  variant?: IVariant;
}

const ButtonVariants = styledMap<IProps, IVariant>("variant");

const Button = styled.button.withConfig<IProps>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["round", "large", "fluid", "variant"].includes(prop) && defaultValidatorFn(prop),
})`
  ${Typography.linkButton}

  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: ${({ round }) => !round && "16rem"};
  transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  will-change: background-color;

  border-radius: ${({ round }) => round && "100%"};
  padding: ${({ theme, round, large }) => !round && `${theme.spacing(large ? 4 : 2)} ${theme.spacing(2)}`};
  height: ${({ round }) => round && "4.8rem"};
  width: ${({ round, fluid }) => (round ? "4.8rem" : fluid && "100%")};

  ${up("lg")} {
    padding: ${({ theme, round, large }) => !round && `${theme.spacing(large ? 4 : 2)} ${theme.spacing(4)}`};
  }

  ${ButtonVariants({
    primary: css`
      background-color: ${({ theme }) => theme.palette.fuchsia};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.fuchsiaAlt};
      }
    `,
    secondary: css`
      background-color: ${({ theme }) => theme.palette.anotherBlue};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.anotherBlueAlt};
      }
    `,
    dark: css`
      background-color: ${({ theme }) => theme.palette.greyNightmare};
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        background-color: ${({ theme }) => theme.palette.greyNightmareAlt};
      }
    `,
    grey: css`
      background-color: ${({ theme }) => theme.palette.lightGrey};
    `,
  })}
`;

Button.defaultProps = {
  variant: "primary",
};

export default Button;
