import { up } from "styled-breakpoints";
import { css, DefaultTheme, ThemedStyledProps, ThemeProps, FlattenInterpolation } from "styled-components";

type IBreakpoint = keyof DefaultTheme["breakpoints"];
export type IResponsiveProp<T> = T | Partial<Record<IBreakpoint, T>>;

export const styledMap = <P, V extends string>(variantKey: keyof P) => (
  styleVariants: Record<V, FlattenInterpolation<ThemeProps<DefaultTheme>>>
) => (props: P) => {
  const variantProp = (props[variantKey] as unknown) as V;
  if (variantProp) return styleVariants[variantProp];
};

export const stlyedBreakPoints = <P, V>(variantKey: keyof P) => (
  styleFn: (value: V) => FlattenInterpolation<ThemeProps<DefaultTheme>> | null
) => (props: ThemedStyledProps<P, DefaultTheme>) => {
  const responsiveProp = props[variantKey] as IResponsiveProp<V>;
  if (!responsiveProp) return null;

  if (typeof responsiveProp === "object") {
    const styles = Object.entries(responsiveProp).flatMap(([bp, val]) => {
      return css`
        ${up(bp)} {
          ${styleFn(val)}
        }
      `;
    });
    return styles;
  } else {
    return styleFn(responsiveProp);
  }
};
