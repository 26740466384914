import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, withPrefix } from "gatsby";
import { useTranslatedPagePath } from "context/Intl";

interface IProps {
  language?: string;
  title?: string;
  description?: string;
  canonical?: string;
  thumbnail?: string;
  pathname: string;
}

const SiteHead = ({ title, description, thumbnail, pathname, language }: IProps) => {
  const { site: siteData, icon } = useStaticQuery<Gatsby.SiteHeadQuery>(SiteHeadQuery);
  const translatedPagePath = useTranslatedPagePath();

  const { site, defaultTitle, titleTemplate, defaultDescription, defaultLanguage, siteUrl, color, twitter } =
    siteData?.siteMetadata || {};

  const seo = {
    language: language || defaultLanguage,
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: thumbnail || icon?.publicURL,
    twitter,
  };

  const inavtiveLanguage = seo.language === "en" ? "es" : "en";

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang={seo.language} />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="theme-color" content={color} />
      <meta name="application-name" content={site} />

      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={site} />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

      <meta name="twitter:creator" content={seo.twitter} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:url" content={seo.url} />

      <meta name="facebook-domain-verification" content="gibuxvp354y5d5fgsb6c7m0wskpvsq" />

      <link rel="canonical" href={seo.url} />
      {translatedPagePath && <link rel="alternate" hrefLang={inavtiveLanguage} href={siteUrl + translatedPagePath} />}

      <script type="text/javascript" async>{`
        window.WIDGET_ID = "${process.env.GATSBY_CHATBOT_ID!}";

        (function() {
          const f = function() {
            const d = document;
            const l = d.createElement('script');
            l.type = 'text/javascript';
            l.async = true;
            l.src = 'https://api.airbud.io/widgets/widget.js?id=' + window.WIDGET_ID;
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(l, x);
          };
          f();
        })();
      `}</script>

      <script async src={withPrefix("js/montefiore-alert-bar.js")}></script>

      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.8.2/css/all.css"
        integrity="sha384-xVVam1KS4+Qt2OrFa+VdRUoXygyKIuNWUUUBZYv+n27STsJ7oDOHJgfF0bNKLMJF"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
};

const SiteHeadQuery = graphql`
  query SiteHead {
    site {
      siteMetadata {
        site
        siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultLanguage: language
        color
        twitter
      }
    }
    icon: file(name: { eq: "icon" }) {
      publicURL
    }
  }
`;

export default SiteHead;
