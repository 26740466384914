import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import PlayIcon from "assets/play.svg";
import Button from "./Button";

interface IProps {
  src?: string;
  alt?: string;
  thumbnail?: string;
}

const VideoContainer = styled.div`
  display: block;
  position: relative;
  padding-bottom: 56.25%; /* 16:9*/
  width: 100%;

  /* React Player */
  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PlayButton = styled(Button)`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
`;

const VideoPlayer: React.FC<IProps> = ({ src, thumbnail }) => {
  return (
    <VideoContainer>
      <ReactPlayer
        width="100%"
        height="100%"
        url={src}
        light={thumbnail}
        playIcon={
          <PlayButton variant="grey" round={true} aria-label="Play video">
            <PlayIcon aria-label="Videoplayer icon" />
          </PlayButton>
        }
      />
    </VideoContainer>
  );
};

export default VideoPlayer;
