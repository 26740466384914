import React from "react";
import * as UI from "components/ui";

function isVideoFragment(p: any): p is Gatsby.VideoFragmentFragment {
  return !!p?.embeddedVideo;
}

function isImageFragment(p: any): p is Gatsby.ImageFragmentFragment {
  return !!p?.r?.image;
}

const Media: React.FC<Gatsby.MediaBlockFragment> = ({ r }) => {
  const media = r?.media;

  if (isVideoFragment(media) && !!media.embeddedVideo) {
    const videoThumb = media.r?.thumbnail?.localFile?.publicURL || undefined;
    const altName = "";
    return <UI.Video src={media.embeddedVideo} thumbnail={videoThumb} alt={altName} />;
  } else if (isImageFragment(media) && !!media.r?.image?.localFile) {
    const altName = (media as any).imageDetails?.alt || "";
    return <UI.Image {...media.r?.image?.localFile} alt={altName} />;
  } else {
    return null;
  }
};

export default Media;
