import React, { createContext, useContext, useState } from "react";

type Action<T = unknown> = React.Dispatch<React.SetStateAction<T>>;

interface IState {
  overlayIsVisible: boolean;
  mobileNavIsVisible: boolean;
  setOverlayIsVisible: Action<boolean>;
  setMobileNavIsVisible: Action<boolean>;
}

const Context = createContext({} as IState);

export const useAppStore = () => {
  return useContext(Context);
};

const AppStoreProvider: React.FC = ({ children }) => {
  const [overlayIsVisible, setOverlayIsVisible] = useState(false);
  const [mobileNavIsVisible, setMobileNavIsVisible] = useState(false);

  const store: IState = {
    overlayIsVisible,
    setOverlayIsVisible,
    mobileNavIsVisible,
    setMobileNavIsVisible,
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export default AppStoreProvider;
